<template>
  <div>
    <RichTextVueParser v-bind="props" :richText />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import type { RichTextProps } from '@backmarket/http-api/src/api-specs-content/models/rich-text-content'
import { useI18nLocale } from '@backmarket/nuxt-module-i18n/useI18nLocale'

import { parseRichText } from './DynamicTextDates.utils'
import RichTextVueParser from './RichTextVueParser.vue'

const props = withDefaults(defineProps<RichTextProps>(), {
  lightText: false,
  ignoreLinks: false,
  defaultStyle: true,
})

const locale = useI18nLocale()

const richText = computed(() => {
  return parseRichText(props.richText, locale)
})

// This event is common to every CMS block in order to hide the block containers
// on demand
defineEmits(['error'])
</script>
